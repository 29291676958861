import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import { StrongWhite } from "../../../components/Textstyles/Styles";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 70",
  week: "Semana 10",
  day: "22",
  month: "May",
  monthNumber: "05",
  date: "2020-05-22",
  path: "/cronologia/semana-10#dia-22-may/",
};

const Day70 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="RU"
          data="casosFallecidos"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 455 nuevos positivos y 52 personas fallecidas. Permanecen
          hospitalizadas 124.521 personas, 95 menos que el día anterior. El
          número de casos ingresados en la UCI asciende a 11.454, lo que supone
          un aumento de 9 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,0 % y la tasa de recuperados superior al 53,7 %.
        </ModText>
        <ModDailyHighlight>
          <StrongWhite> China</StrongWhite> presenta resultados iniciales
          positivos de una vacuna que crea inmunidad
        </ModDailyHighlight>
        <ModText>
          El Gobierno ha hecho pública la{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/05/23/pdfs/BOE-A-2020-5265.pdf">
            Orden que flexibiliza las restricciones
          </InlineLink>{" "}
          de ámbito nacional dentro del Plan de transición hacia una nueva
          normalidad. A partir del próximo lunes 25 de mayo, toda España estará,
          al menos, en fase 1 y el 46% de la población en fase 2.
        </ModText>
        <ModText>
          Asimismo, el Boletín Oficial del Estado también ha publicado la{" "}
          <InlineLink link="https://www.boe.es/boe/dias/2020/05/22/pdfs/BOE-A-2020-5218.pdf">
            Orden
          </InlineLink>{" "}
          que recoge las medidas de flexibilización de determinadas
          restricciones a pequeños municipios y a entes locales, de ámbito
          territorial inferior, con menos de 10.001 habitantes y una densidad de
          población inferior a 100 habitantes por kilómetro cuadrado.
        </ModText>
        <ModText>
          Entre las medidas aprobadas para estos municipios, se encuentran:
        </ModText>
        <ModDotList>
          Los pueblos con menos de 10.001 habitantes y una densidad de población
          inferior a 100 habitantes por kilómetro cuadrado que estén en fase 0 y
          1 podrán acogerse a algunas medidas previstas para la fase 2.
        </ModDotList>
        <ModDotList>
          Se eliminan las franjas horarias que determinan la actividad física no
          profesional al aire libre y las salidas de los menores de 14 años.
        </ModDotList>
        <ModGraph name="ejercicio-aire-libre" alt="ejercicio al aire libre" />
        <ModDotList>
          Se podrán autorizar los mercados al aire libre o de venta no
          sedentaria en la vía pública, dando preferencia a los de productos
          alimentarios y de primera necesidad.
        </ModDotList>
        <ModGraph name="mercado-aire-libre" alt="mercadillo al aire libre" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day70;
